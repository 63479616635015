import React from 'react';
import {WhyChooseUsSection,WhyChooseUsWrapper,LeftLayout,RightLayout,
    WhyChooseUsHeading,WhyChooseUsSingle,NumberLayout,TextLayout,SubHeading,
    Content,ImgIcon
} from './whychooseus.style';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useStaticQuery, graphql } from 'gatsby';
import GatsImg from "gatsby-image";

const WhyChooseUsPage  = () => {
    const JSONData  = useStaticQuery(graphql`
    query {
        aboutus2PageJson{
            WhyChooseUs{
                LeftImage{
                    childImageSharp{
                        fluid(quality: 100){
                        ...GatsbyImageSharpFluid
                        }
                    }
                }
                WhyChooseUsHeading
                Details{
                        NumberLayout
                        SubHeading
                        Content
                    }
            }
        }
    }
  `);

    return (
            <WhyChooseUsSection>
                <WhyChooseUsWrapper>
                    <LeftLayout>
                    <GatsImg 
                        fluid={JSONData.aboutus2PageJson.WhyChooseUs.LeftImage.childImageSharp.fluid} 
                        alt=""
                    />
                    </LeftLayout>
                    <RightLayout>
                        <WhyChooseUsHeading>
                        {JSONData.aboutus2PageJson.WhyChooseUs.WhyChooseUsHeading}
                        </WhyChooseUsHeading>
                        <WhyChooseUsSingle>
                            <NumberLayout BeforeReq>
                                <ImgIcon
                                src={JSONData.aboutus2PageJson.WhyChooseUs.Details[0].NumberLayout}
                                alt=""
                                />
                            </NumberLayout>
                           <TextLayout>
                               <SubHeading>
                               {JSONData.aboutus2PageJson.WhyChooseUs.Details[0].SubHeading}
                               </SubHeading>
                               <Content>
                               {JSONData.aboutus2PageJson.WhyChooseUs.Details[0].Content}
                               </Content>
                           </TextLayout>
                       </WhyChooseUsSingle>
                       <WhyChooseUsSingle>
                            <NumberLayout BeforeReq>
                                <ImgIcon
                                src={JSONData.aboutus2PageJson.WhyChooseUs.Details[1].NumberLayout}
                                alt=""
                                />
                            </NumberLayout>
                           <TextLayout>
                               <SubHeading>
                               {JSONData.aboutus2PageJson.WhyChooseUs.Details[1].SubHeading}
                               </SubHeading>
                               <Content>
                               {JSONData.aboutus2PageJson.WhyChooseUs.Details[1].Content}
                               </Content>
                           </TextLayout>
                       </WhyChooseUsSingle>
                       <WhyChooseUsSingle>
                            <NumberLayout>
                                <ImgIcon
                                src={JSONData.aboutus2PageJson.WhyChooseUs.Details[2].NumberLayout}
                                alt=""
                                />
                            </NumberLayout>
                           <TextLayout>
                               <SubHeading>
                               {JSONData.aboutus2PageJson.WhyChooseUs.Details[2].SubHeading}
                               </SubHeading>
                               <Content>
                               {JSONData.aboutus2PageJson.WhyChooseUs.Details[2].Content}
                               </Content>
                           </TextLayout>
                       </WhyChooseUsSingle>
                    </RightLayout>
                </WhyChooseUsWrapper>
            </WhyChooseUsSection>
    );
}
export default WhyChooseUsPage;