import styled from 'styled-components';
import {Commonpara,Commonh4,SectionHeading} from '../../../components/common.style';
import {SocialFacebook} from 'styled-icons/typicons/SocialFacebook'
import {SocialTwitter} from 'styled-icons/typicons/SocialTwitter'
import {SocialLinkedin} from 'styled-icons/typicons/SocialLinkedin'
import {SocialInstagram} from 'styled-icons/typicons/SocialInstagram'
import {device} from '../../../components/device';

export const TeamSection = styled.section`
    padding:100px 0px;

    @media ${device.tablet} {
        padding:80px 10px 50px;
    }
`;

export const HeadingLayout = styled.div`

`;

export const TeamHeading = styled(SectionHeading)`
    margin-bottom:75px;

    @media ${device.tablet} {
        margin-bottom:60px;
    }
`;

export const TeamLayout = styled.div`
    background:#fafafa;
    padding:30px;
    text-align:center;

    @media ${device.laptop} {
        padding:20px;
    }
    @media ${device.tablet} {
        padding:30px;
        margin-bottom:30px;
    }
`;

export const TeamImageHolder = styled.div`
    width:200px;
    border-radius:100%;
    margin:0px auto 30px;

    @media ${device.laptop} {
        width:150px;
    }
    @media ${device.tablet} {
        width:200px;
    }
    @media ${device.mobileM} {
        width:150px;
    }
`;

export const TeamDetailsLayout = styled.div`

`;

export const Name = styled(Commonh4)`
    font-size:22px;
    line-height:22px;
    margin-bottom:10px;

    @media ${device.laptop} {
        font-size:20px;
    }

    @media ${device.tablet} {
        font-size:22px;
    }
`;

export const Designation = styled(Commonpara)`
    color:#2560d4;
`;

export const Divider = styled.hr`
    margin:10px 0px;
`;

export const SocialIconLayout = styled.div`

`;


export const InstaIcon = styled(SocialInstagram)`
    width: 32px;
    color: #fff;
    padding: 6px;
    border-radius:100%;
    margin: 10px 5px 0px;
    background: #ec008caa;
    transition:all .5s;
    :hover{
        background: none;
        color: #ec008c;
        border:2px solid #ec008c;
    }
`;
export const LinkedinIcon = styled(SocialLinkedin)`
    width: 32px;
    color: #fff;
    padding: 6px;
    border-radius:100%;
    margin: 10px 5px 0px;
    background: #2e318f;
    transition:all .5s;
    :hover{
        background: none;
        color: #2e318f;
        border:2px solid #2e318f;
    }
`;
export const TwitterIcon = styled(SocialTwitter)`
    width: 32px;
    color: #fff;
    padding: 6px;
    border-radius:100%;
    margin: 10px 5px 0px;
    background: #00aeee;
    transition:all .5s;
    :hover{
        background: none;
        color: #00aeee;
        border:2px solid #00aeee;
    }
`;
export const FbIcon = styled(SocialFacebook)`
    width: 32px;
    color: #fff;
    padding: 6px;
    border-radius:100%;
    margin: 10px 5px 0px;
    background: #3949aa;
    transition:all .5s;
    :hover{
        background: none;
        color: #3949aa;
        border:2px solid #3949aa;
    }
`;


