import styled from 'styled-components';
import {device} from '../../../components/device';
import {Commonpara,Commonh4,SectionHeading} from '../../../components/common.style';
import BackgroundImg from '../../../assets/aboutus-2-images/banner-2.jpg'

export const WhyChooseUsSection = styled.section`
    padding:100px 0px;
    background-image:url(${BackgroundImg});
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;

    @media ${device.tablet} {
        padding:80px 10px 80px;
    }
`;

export const WhyChooseUsWrapper = styled.div`
    display:flex;
    align-items:center;

    @media ${device.minlaptopL} {
        max-width:1140px;
        margin:auto;
    }

    @media ${device.laptop} {
        flex-direction:column;
    }
    
`;

export const LeftLayout = styled.div`
    width:50%;
    padding-left:10px;
    flex-shrink:0;

    @media ${device.minlaptopL} {
        padding-left:15px;
    }

    @media ${device.laptop} {
        width:100%;
        max-width: 720px;
        padding-right:15px;
        padding-left:15px;
        margin-bottom:40px;
    }

    @media ${device.tablet} {
        width:100%;
        padding-right:15px;
        padding-left:15px;
        margin-bottom:40px;
    }
`;

export const RightLayout = styled.div`
    width:50%;
    padding-left:60px;
    padding-right:15px;

    @media ${device.laptopL} {
        margin-right: calc(50% - 570px);
    }

    @media ${device.laptopM} {
        margin-right: calc(50% - 480px);
    }

    @media ${device.laptop} {
        width:100%;
        max-width: 720px;
        margin:auto;
        padding-left:15px;
    }

    @media ${device.tablet} {
        width:100%;
        max-width: 540px;
        margin:auto;
        padding-left:15px;
    }
`;

export const WhyChooseUsHeading = styled(SectionHeading)`
    color:#fff;
    text-align:left;
    margin-bottom:40px;

    @media ${device.tablet} {
        text-align:center;
        margin-bottom:40px;
    }
`;

export const WhyChooseUsSingle = styled.div`
    display:flex;
    margin-bottom:30px;

    @media ${device.laptop} {
        margin-bottom:20px;
    }

    :last-child{
        margin-bottom:0px;
    }

`;

export const NumberLayout = styled.div`
    position: relative;
    flex-shrink:0;
    ::before {
        content: "";
        border-left: ${props => props.BeforeReq?"1px solid #fff":"none"};
        position: absolute;
        height:100%;
        left:50%;
        margin-top:60px; //should be same as image height
    }
`;

export const ImgIcon = styled.img`
    width:60px;
`;

export const TextLayout = styled.div`
    margin-left:20px;
    margin-top:10px;
`;

export const SubHeading = styled(Commonh4)`
    color:#fff;
    text-align:left;
    font-size:22px;
    line-height:26px;
    margin-bottom:15px;
`;

export const Content = styled(Commonpara)`
    color:#fff;
    text-align:left;
    opacity:0.9;
    margin-bottom:0px;
`;