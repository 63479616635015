import styled from 'styled-components';
import {Commonpara,Commonh4,SectionHeading} from '../../../components/common.style';
import {device} from '../../../components/device';

export const WhatWeDoSection = styled.section`
    padding:100px 0px 80px;
    background:#fafafa;

    @media ${device.laptop} {
        padding:100px 0px 70px;
    }
    @media ${device.tablet} {
        padding:80px 10px 50px;
    }
`;

export const HeadingWrapper = styled.div`

`;

export const WhatWeDoHeading = styled(SectionHeading)`
    margin-bottom:75px;

    @media ${device.tablet} {
        margin-bottom:60px;
    }
`;

export const SingleBlockLayout = styled.div`
    @media ${device.laptop} {
        margin-bottom:30px;
    }
`;

export const ImageHolder = styled.div`
    text-align:center;
    margin-bottom:20px;
`;

export const ImageIcon = styled.img`
    width:80px;
`;

export const WhatWeDoTitle = styled(Commonh4)`
    font-size:24px;
    line-height:30px;
`;

export const WhatWeDoContent = styled(Commonpara)`
    text-align:center;
`;
