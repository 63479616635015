import React from 'react';
import {WhatWeDoSection,HeadingWrapper,WhatWeDoHeading,SingleBlockLayout,ImageHolder,
    ImageIcon,WhatWeDoTitle,WhatWeDoContent
} from './whatwedo.style';
import {Container, Row, Col} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useStaticQuery, graphql } from 'gatsby';

const WhatWeDoPage  = () => {
    const JSONData  = useStaticQuery(graphql`
    query {
        aboutus2PageJson{
            WhatWeDo{
                WhatWeDoHeading
                Details{
                    WhatWeDoImage
                    SubHeading
                    Content
                }
            }
        }
    }
  `);

    return (
        <WhatWeDoSection>
            <Container>
                <Row>
                    <Col md={12}>
                    <HeadingWrapper>
                        <WhatWeDoHeading>
                        {JSONData.aboutus2PageJson.WhatWeDo.WhatWeDoHeading}
                        </WhatWeDoHeading>
                    </HeadingWrapper>
                    </Col>
               </Row>
               <Row>
                   <Col md={6} lg={3}>
                        <SingleBlockLayout>
                            <ImageHolder>
                            <ImageIcon 
                                src={JSONData.aboutus2PageJson.WhatWeDo.Details[0].WhatWeDoImage} 
                                alt=""
                            />
                            </ImageHolder>
                            <WhatWeDoTitle>
                            {JSONData.aboutus2PageJson.WhatWeDo.Details[0].SubHeading}
                            </WhatWeDoTitle>
                            <WhatWeDoContent>
                            {JSONData.aboutus2PageJson.WhatWeDo.Details[0].Content}
                            </WhatWeDoContent>
                        </SingleBlockLayout>
                   </Col>
                   <Col md={6} lg={3}>
                        <SingleBlockLayout>
                            <ImageHolder>
                            <ImageIcon 
                                src={JSONData.aboutus2PageJson.WhatWeDo.Details[1].WhatWeDoImage} 
                                alt=""
                            />
                            </ImageHolder>
                            <WhatWeDoTitle>
                            {JSONData.aboutus2PageJson.WhatWeDo.Details[1].SubHeading}
                            </WhatWeDoTitle>
                            <WhatWeDoContent>
                            {JSONData.aboutus2PageJson.WhatWeDo.Details[1].Content}
                            </WhatWeDoContent>
                        </SingleBlockLayout>
                   </Col>
                    <Col md={6} lg={3}>
                        <SingleBlockLayout>
                            <ImageHolder>
                            <ImageIcon 
                                src={JSONData.aboutus2PageJson.WhatWeDo.Details[2].WhatWeDoImage} 
                                alt=""
                            />
                            </ImageHolder>
                            <WhatWeDoTitle>
                            {JSONData.aboutus2PageJson.WhatWeDo.Details[2].SubHeading}
                            </WhatWeDoTitle>
                            <WhatWeDoContent>
                            {JSONData.aboutus2PageJson.WhatWeDo.Details[2].Content}
                            </WhatWeDoContent>
                        </SingleBlockLayout>
                    </Col>
                    <Col md={6} lg={3}>
                        <SingleBlockLayout>
                            <ImageHolder>
                            <ImageIcon 
                                src={JSONData.aboutus2PageJson.WhatWeDo.Details[3].WhatWeDoImage} 
                                alt=""
                            />
                            </ImageHolder>
                            <WhatWeDoTitle>
                            {JSONData.aboutus2PageJson.WhatWeDo.Details[3].SubHeading}
                            </WhatWeDoTitle>
                            <WhatWeDoContent>
                            {JSONData.aboutus2PageJson.WhatWeDo.Details[3].Content}
                            </WhatWeDoContent>
                        </SingleBlockLayout>
                   </Col>
               </Row>
            </Container>
        </WhatWeDoSection>
    );
}
export default WhatWeDoPage;