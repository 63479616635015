import styled from 'styled-components';
import {device} from '../../../components/device';
import {Commonpara,SectionHeadingMultiLine} from '../../../components/common.style';
import {Row} from 'react-bootstrap';


export const AboutSection = styled.section`
    padding:100px 0px;
    background:#f9fafc;

    @media ${device.laptop} {
        padding:100px 0px 80px;
    }
    @media ${device.tablet} {
        padding:80px 10px 60px;
    }
`;

export const AboutInner = styled.div`
    
`;

export const AboutRow = styled(Row)`
    flex-direction: row-reverse;
    align-items:center;
    @media ${device.tablet} {
        :last-child{
            padding-bottom:0px;
        }
    }
`;

export const AboutRight = styled.div`
    position: relative;
    text-align:right;

    @media ${device.tablet} {
        text-align:right;
    }
`;

export const AboutRightImgHolder = styled.div`
    padding:0px 40px;
`;

export const AboutLeft = styled.div`
    @media ${device.tablet} {
        text-align:left;
    }
`;

export const AboutLeftHeading = styled(SectionHeadingMultiLine)`
    @media ${device.tablet} {
        margin-top:40px;
    }
`;

export const AboutLeftPara = styled(Commonpara)`
    text-align:left;
    font-size:14px;
`;
